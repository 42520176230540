var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "showcase-file-upload" }, [
    _c(
      "div",
      { staticClass: "upload-file-section" },
      [
        _c(
          "draggable",
          {
            on: { end: _vm.onEndDrag },
            model: {
              value: _vm.uploadedFiles,
              callback: function ($$v) {
                _vm.uploadedFiles = $$v
              },
              expression: "uploadedFiles",
            },
          },
          [
            _c(
              "transition-group",
              { staticClass: "uploaded-files", attrs: { name: "list" } },
              _vm._l(_vm.uploadedFiles, function (file, index) {
                return _c(
                  "div",
                  {
                    key: file.id || file.name,
                    class: {
                      thumb: _vm.uploadedFiles.length > 1,
                      admin: _vm.$route.name === "admin.showcase.submission",
                    },
                  },
                  [
                    _c("ShowcaseFileIndicator", {
                      attrs: {
                        file: file,
                        "allow-delete": _vm.uploadedFiles.length > 0,
                        "is-uploading": _vm.isItemUploading(index),
                      },
                      on: {
                        onCancelUpload: function ($event) {
                          return _vm.cancelUpload(index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "button-holder" }, [
      _c("input", {
        ref: "uploadedFiles",
        staticClass: "input-file",
        attrs: {
          id: `files_${_vm.randomId}`,
          type: "file",
          name: "file",
          multiple: "",
        },
        on: { change: _vm.onFileChange },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "file-upload-button-label",
          attrs: { for: `files_${_vm.randomId}`, tabindex: "0" },
          on: {
            keydown: function ($event) {
              return _vm.$triggerClickEvent($event, `files_${_vm.randomId}`)
            },
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.uploadButtonText) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-input" }, [
      _vm.hasError
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }