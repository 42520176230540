var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subpage-layout" }, [
    _c(
      "div",
      { staticClass: "showcase-sidebar" },
      [
        _vm.showcase
          ? _c("back-button", {
              attrs: {
                "showcase-id": _vm.showcaseId,
                "button-text": "Back to " + _vm.showcase.name,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "showcase-success" }, [
      _c("h1", { staticClass: "title" }, [
        _vm._v("\n      " + _vm._s(_vm.successTitle) + "\n    "),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Thanks for sharing!")]),
      _vm._v(" "),
      _c("p", {
        directives: [
          {
            name: "sanitize",
            rawName: "v-sanitize",
            value: _vm.successMessage,
            expression: "successMessage",
          },
        ],
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }