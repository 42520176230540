var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormRoot",
    {
      staticClass: "admin-showcase-submission-form",
      attrs: {
        "form-id": "showcase-submission",
        "enable-submit": true,
        "show-save-button": false,
      },
    },
    [
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Your Information\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "fullName",
            attrs: {
              model: _vm.fullName,
              placeholder: "Full name",
              label: "Full Name",
              required: "true",
              validate: "true",
              "hide-asterisk": true,
            },
            on: {
              "update:model": function ($event) {
                _vm.fullName = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "email",
            attrs: {
              model: _vm.email,
              label: "Email Address",
              placeholder: "email@domain.com",
              required: "true",
              validate: "true",
              "hide-asterisk": true,
            },
            on: {
              "update:model": function ($event) {
                _vm.email = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "agency",
            attrs: {
              model: _vm.agency,
              label: "Agency",
              placeholder: "Agency title or inhouse",
              required: "false",
              validate: "true",
              "hide-asterisk": true,
            },
            on: {
              "update:model": function ($event) {
                _vm.agency = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", { staticClass: "subhead-2" }, [
          _vm._v("\n      Submission category\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns" },
          [
            _c("CheckboxArrayInput", {
              ref: "category",
              attrs: {
                name: "category",
                model: _vm.category,
                list: _vm.categorySelectList,
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.category = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", { staticClass: "subhead-2" }, [
          _vm._v("\n      Business Unit\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns" },
          [
            _c("CheckboxArrayInput", {
              ref: "industry",
              attrs: {
                name: "industry",
                model: _vm.industry,
                list: _vm.industrySelectList,
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.industry = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("fieldset", [
        _c("label", { staticClass: "subhead-2" }, [
          _vm._v("\n      Related products\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns" },
          [
            _c("CheckboxArrayInput", {
              ref: "capability",
              attrs: {
                name: "capability",
                model: _vm.capability,
                list: _vm.capabilitySelectList,
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.capability = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Description\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "title",
            attrs: {
              model: _vm.title,
              required: "true",
              validate: "true",
              placeholder:
                "This title will be shown on the overview page so keep it short and clear (e.g. 'Corporate website'). Maximum of 50 characters, depending on word length.",
              maxlength: _vm.maxTitleChars,
              label: "Short and clear project title",
              "help-text":
                "This title will be shown on the overview page so keep it short and clear. Maximum of 50 characters, depending on word length.",
            },
            on: {
              "update:model": function ($event) {
                _vm.title = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "description",
            attrs: {
              placeholder: "",
              model: _vm.description,
              required: "true",
              validate: "true",
              maxlength: _vm.maxDescriptionChars,
              rows: 4,
              label: "Project details",
              "help-text":
                "Tell us more about the goals of the project. Perhaps tell us about the insights and/or inspiration for the project",
            },
            on: {
              "update:model": function ($event) {
                _vm.description = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "description",
            attrs: {
              placeholder: "",
              model: _vm.description,
              required: "true",
              validate: "true",
              maxlength: _vm.maxImpactChars,
              rows: 4,
              label: "Project details",
            },
            on: {
              "update:model": function ($event) {
                _vm.description = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "sub-title" }, [
        _vm._v("\n    Images and files\n  "),
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("h5", [_vm._v("Upload 1-5 showcase image(s) and/or video(s)")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      Upload JPG or PNG photo(s) that are less than 5MB each. The first photo uploaded will be your featured photo. Please use a 16:9 ratio (1128px wide by 632px tall). Videos must be submitted in MP4 format. File names should not contain any spaces or special characters.\n    "
            ),
          ]),
          _vm._v(" "),
          _c("label", [_vm._v("Upload up to 10 relevant files")]),
          _vm._v(" "),
          _vm.fileHashCollection && _vm.fileHashCollection.length
            ? _c("ShowcaseFileUpload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add file(s)",
                  "upload-files-demo": "uploadFilesDemo",
                  "initial-file-hash-collection": _vm.fileHashCollection,
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Include any relevant weblinks, one per line\n    "),
          ]),
          _vm._v(" "),
          _c("showcase-links", {
            ref: "showcaseLinks",
            attrs: {
              links: _vm.links,
              placeholder_text: "e.g. Policies",
              placeholder_url: "e.g. http://www.site.com/link",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }