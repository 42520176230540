var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-submission-info", class: _vm.classes },
    [
      _vm.fullName
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Contact person for project:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.fullName,
                  expression: "fullName",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.contactEmail
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Contact's email address:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.contactEmail,
                  expression: "contactEmail",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.agency
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Agency:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.agency,
                  expression: "agency",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.category
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Submission category:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.category,
                  expression: "category",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.industry
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Business unit:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.industry,
                  expression: "industry",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.capability
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Related product:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.capability,
                  expression: "capability",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.year
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Year:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.year,
                  expression: "year",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.links.length > 0
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Related Links:\n    "),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "links" },
              _vm._l(_vm.links, function (link, index) {
                return _c("li", { key: index }, [
                  _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                    _vm._v(_vm._s(link.text)),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }