/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  icons: {
    type: 'default',
    custom: {
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z" fill="#241F20"/>'
      },
    }
  },
  config: {
    show_utility_bar: false
  },
  colors: {
    brand_1: {
      name: 'Clockwork',
      value: '#ff9731'
    },
    brand_2: {
      name: 'Iceman',
      value: '#a4bcc2'
    },
    mono_1: {
      name: 'Silver',
      value: '#c0c0c0'
    },
    mono_2: {
      name: 'Jet',
      value: '#343434'
    },
    error: {
      name: 'Error',
      value: '#cc0000'
    },
    info: {
      name: 'Info',
      value: '#0099cc'
    },
    success: {
      name: 'Success',
      value: '#007e33'
    },
    warning: {
      name: 'Warning',
      value: '#ff8800'
    }
  },
  fonts: {
    font_1: {
      name: 'Serif',
      value: 'Georgia, "Times New Roman", Times, serif'
    },
    font_2: {
      name: 'Sans-serif',
      value: 'Arial, Helvetica, sans-serif'
    },
    font_3: {
      name: 'Modern',
      value: 'Tahoma, Verdana, Geneva, sans-serif'
    },
    font_4: {
      name: 'Monospace',
      value: '"Courier New", Courier, monospace'
    }
  }
}
