var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          "\n    Submit to " +
            _vm._s(_vm.$t("features_showcase_general_label")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "caption required" }, [
        _vm._v(
          "\n    You are one step away from sharing your showcase! Just fill out the form\n    below, add preview images and files. Fields marked * are required.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "FormRoot",
        {
          attrs: {
            "form-id": "showcase-submission",
            "enable-submit": !_vm.submitting,
            "submit-button-text": `${
              _vm.submitting ? "Submitting..." : "Submit"
            }`,
          },
        },
        [
          _c("fieldset", { staticStyle: { "margin-bottom": "0px" } }, [
            _c(
              "div",
              { staticClass: "user-info" },
              [
                _c("h1", [_vm._v("Product details")]),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "fullName",
                  attrs: {
                    model: _vm.fullName,
                    label: "Contact person for project",
                    placeholder: "",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": false,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.fullName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "help-caption" }, [
                  _vm._v(
                    "\n          Who can be contacted for information about this showcase?\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "email",
                  attrs: {
                    model: _vm.email,
                    label: "Contact’s email address",
                    placeholder: "",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": false,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.email = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", { staticStyle: { "margin-bottom": "0px" } }, [
            _c(
              "div",
              { staticClass: "user-info" },
              [
                _c("p", { staticClass: "help-caption" }, [
                  _vm._v("\n          Who created this showcase?\n        "),
                ]),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "agency",
                  attrs: {
                    model: _vm.agency,
                    label: "Agency title or inhouse",
                    placeholder: "",
                    required: "false",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.agency = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "form-input text-input vue-select-field" },
            [
              _c("label", [_vm._v("Submission category *")]),
              _vm._v(" "),
              _c("VueSelect", {
                staticClass: "entities-dropdown vue-select-input",
                attrs: {
                  multiple: "",
                  placeholder: "",
                  searchable: false,
                  clearable: false,
                  "hide-asterisk": true,
                  required: "true",
                  options: _vm.categorySelectList,
                  validate: "true",
                },
                scopedSlots: _vm._u([
                  {
                    key: "open-indicator",
                    fn: function () {
                      return [
                        _c("Icon", { attrs: { id: "ic_arrow_drop_down" } }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "option",
                    fn: function (option) {
                      return [_c("span", [_vm._v(_vm._s(option.label))])]
                    },
                  },
                ]),
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "vue-select-input-error error",
                  staticStyle: {
                    color: "var(--colors_global_error)",
                    "font-size": "0.8rem",
                  },
                },
                [_vm._v("\n        This field is required\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "help-caption" }, [
            _vm._v(
              "\n      Choose the one category that suits your showcase best.\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "form-input text-input" },
            [
              _c("label", [_vm._v("Business unit")]),
              _vm._v(" "),
              _c("VueSelect", {
                staticClass: "entities-dropdown",
                attrs: {
                  multiple: "",
                  placeholder: "",
                  searchable: false,
                  clearable: false,
                  "hide-asterisk": false,
                  options: _vm.industrySelectList,
                  required: "false",
                },
                scopedSlots: _vm._u([
                  {
                    key: "open-indicator",
                    fn: function () {
                      return [
                        _c("Icon", { attrs: { id: "ic_arrow_drop_down" } }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "option",
                    fn: function (option) {
                      return [_c("span", [_vm._v(_vm._s(option.label))])]
                    },
                  },
                ]),
                model: {
                  value: _vm.industry,
                  callback: function ($$v) {
                    _vm.industry = $$v
                  },
                  expression: "industry",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "form-input text-input" },
            [
              _c("label", [_vm._v("Related products")]),
              _vm._v(" "),
              _c("VueSelect", {
                staticClass: "entities-dropdown",
                attrs: {
                  multiple: "",
                  placeholder: "",
                  searchable: false,
                  clearable: false,
                  "hide-asterisk": true,
                  options: _vm.capabilitySelectList,
                  required: "false",
                },
                scopedSlots: _vm._u([
                  {
                    key: "open-indicator",
                    fn: function () {
                      return [
                        _c("Icon", { attrs: { id: "ic_arrow_drop_down" } }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "option",
                    fn: function (option) {
                      return [_c("span", [_vm._v(_vm._s(option.label))])]
                    },
                  },
                ]),
                model: {
                  value: _vm.capability,
                  callback: function ($$v) {
                    _vm.capability = $$v
                  },
                  expression: "capability",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("h1", { staticClass: "sub-title" }, [
            _vm._v("\n      Description\n    "),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "project-info" },
            [
              _c("TextInput", {
                ref: "title",
                attrs: {
                  model: _vm.title,
                  required: "true",
                  validate: "true",
                  placeholder: "",
                  maxlength: _vm.maxTitleChars,
                  label: "Short and clear project title",
                  "help-text":
                    "This title will be shown on the overview page so keep it short and clear. Maximum of 50 characters, depending on word length.",
                  "hide-asterisk": false,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.title = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "description",
                attrs: {
                  placeholder: "",
                  model: _vm.description,
                  required: "true",
                  validate: "true",
                  maxlength: _vm.maxDescriptionChars,
                  rows: 4,
                  label: "Project details",
                  "help-text":
                    "Explain why - the project was initiated and expected outcomes, who - audience targeted, what - the medium, where - did this appear",
                  "hide-asterisk": false,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.description = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("h1", { staticClass: "sub-title" }, [
            _vm._v("\n      Images and files\n    "),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("h5", [
                _vm._v("Upload 1-5 showcase image(s) and/or video(s)*"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Upload JPG or PNG photo(s) that are less than 5MB each. The first\n        photo uploaded will be your featured photo. Please use a 16:9 ratio\n        (1128px wide by 632px tall). Videos must be submitted in MP4 format.\n        File names should not contain any spaces or special characters.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("label", [_vm._v("Upload up to 10 relevant files")]),
              _vm._v(" "),
              _c("ShowcaseFileUpload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add file(s)",
                  "upload-files-demo": "uploadFilesDemo",
                  "hide-asterisk": true,
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("h5", [_vm._v("Include any relevant weblinks, one per line")]),
              _vm._v(" "),
              _c("showcase-links", {
                ref: "showcaseLinks",
                attrs: {
                  links: _vm.links,
                  placeholder_text: "e.g. Policies",
                  placeholder_url: "e.g. http://www.site.com/link",
                  "hide-asterisk": true,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }