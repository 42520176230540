// clientName MUST be specified, as this is used to build the API URL
// Regex for one lower, one upper, one special, one number, at least 8 characters =
//     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";

const ClientConfig = {
  clientName: 'beam',
  environment: {
    development: {
      login: 'login',
      ssoLogin: '',
      logout: 'login'
    },
    production: {
      login: 'login',
      ssoLogin: '',
      logout: 'login'
    },
    qa: {
      login: 'login',
      ssoLogin: '',
      logout: 'login'
    },
    staging: {
      login: 'login',
      ssoLogin: [],
      logout: 'login'
    },
    demo: {
      login: 'login',
      ssoLogin: '',
      logout: 'login'
    }
  },
  settings: {
    // passwordRegex: '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    passwordRequirements: {
      length: 8,
      lowercase: 1,
      number: 1,
      symbol: 1,
      uppercase: 1
    }
  },
  theme: {
    themeStyles: {
      hasAltHomepageDesktopLogo: false
    }
  },
  include_modules: [],
  exclude_modules: [],
  request_headers: require('@core/js/config/request-headers')
}
export default ClientConfig
